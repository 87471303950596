<script setup>
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()
  const props = defineProps({
    order: {
      type: Object
    }
  })

  const goHome = () => {
    router.replace({ name: 'home' })
  }
</script>

<template>
  <div class="status-box rowSC">
    <div class="suc-ico"></div>
    <div class="right-box">
      <div class="status-title fw500">Congratulations</div>
      <p class="hint-text">
        <span>Thank You for Purchase</span>
        <span class="ml10"><span class="fw500">Order No. </span> {{ props.order.mainOrderNo }} </span>
      </p>
      <!-- <p class="hint-text">We’ve sent a confirmation email to <span class="fw500">+92{{props.order.subOrders[0].mobile}}</span> with the order details.</p> -->
      <div class="hr-primary-btn btn mt10" @click="goHome">Continue Shopping</div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .status-box {
    font-size: 20px;
    padding: 20px 60px;
    .suc-ico {
      width: 76px;
      height: 76px;
      background: url('https://cdn.qingmeidata.com/8406f4bb.png') no-repeat;
      background-size: 100% 100%;
    }
    .right-box {
      margin-left: 30px;
      .status-title {
        font-size: 24px;
        margin-bottom: 5px;
      }
      .hint-text {
        color: rgba(34, 34, 34, 0.8);

      }
      .fw500 {
        font-weight: 500;
        color: var(--btnBgColor);
      }
      .btn {
        width: fit-content;
        padding: 6px 20px 8px 20px;
        border-radius: 24px;
        cursor: pointer;
      }
    }
  }
</style>
